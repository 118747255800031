import React from 'react';
import { graphql } from 'gatsby';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Layout, SEO } from '../components';

const PodcastPage = ({ data }) => {
  const podcasts = data.podcasts.nodes;

  return (
    <Layout>
      <SEO title="Building Pistola podcast" />
      <Container>
        <Box my={4}>
          <Typography variant="overline" component="h1" gutterBottom>
            Building Pistola podcast
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              {podcasts.map(podcast => (
                <iframe
                  key={podcast.id}
                  title={podcast.title}
                  src={podcast.link.replace('/s/', '/e/')}
                  width="100%"
                  height="180"
                  frameBorder="0"
                  scrolling="no"
                  seamless
                  style={{ width: '100%', height: '180px' }}
                ></iframe>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Subscribe to the podcast wherever you get your podcasts, or
                directly from{' '}
                <a
                  href="https://feeds.transistor.fm/building-pistola"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RSS
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default PodcastPage;

export const query = graphql`
  {
    podcasts: allFeedPodcast(sort: { fields: itunes___episode, order: DESC }) {
      nodes {
        id
        title
        pubDate
        link
        content {
          encoded
        }
        enclosure {
          url
          length
        }
        isoDate
        itunes {
          image {
            attrs {
              href
            }
          }
          episode
          duration
        }
      }
    }
  }
`;
